<template>
  <div class="flex flex-col items-start md:px-20 py-10 bg-gradient-to-r from-blue-100 to-blue-50 rounded-lg shadow-md w-full mx-auto" style="height: 200vh;">
    <div class="flex w-full justify-between items-center px-6 py-2">
      <!-- Header content here -->
      <div class="flex-shrink-0">
        <a href="/" class="text-xl font-semibold hover:text-blue-600">//: CURL Converter</a>
      </div>
    </div>
    <div class="w-full">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  @apply bg-gradient-to-r from-blue-100 to-blue-50
}
</style>
