import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue'
import ConverterPage from '../components/ConverterPage.vue'
import NotFound from '../views/NotFound.vue';
import { languages, tools } from '@/utils/languages';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/converter/:language', // Dynamic segment
    name: 'ConverterPage',
    component: ConverterPage,
    props: true,
    beforeEnter: (to, from, next) => {
      const languageExists = languages.some(lang => lang.id === to.params.language);
      const toolsExists = tools.some(tool => tool.id === to.params.language);
      if (!languageExists && !toolsExists) {
        next({ name: 'NotFound' });
      } else {
        next();
      }
    },
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
