<template>
  <div>
    <div class="text-center py-8">
      <h1 class="text-4xl font-bold text-gray-800">Convert CURL commands to {{ capitalizeFirstLetter(language) }}</h1>
    </div>
  
    <div class="relative w-full py-2 h-28 mb-11 px-6">
      <div class="absolute inset-x-0 bottom-0 flex justify-center items-end" v-show="language == 'python'">
        <div class="text-center">
          <div class="mb-4 text-gray-600 text-base not-italic font-medium leading-6 font-inter no-ligatures">Select your library</div>
          <!-- Toggle Switch Button -->
          <div class="h-12 bg-gray-200 rounded-lg flex items-center cursor-pointer">
            <div style="padding: 8px;" :class="[
                  'language-option-library mr-2', 
                  scrapyOption === 'PythonRequests' ? 'pyth-opt-white' : 'pyth-opt-gray'
                 ]"
                 @click="toggleSwitch">Python Requests</div>
            <div style="padding: 8px;" :class="[
                  'language-option-library ml-2', 
                  scrapyOption === 'Scrapy' ? 'pyth-opt-white' : 'pyth-opt-gray'
                 ]"
                 @click="toggleSwitch">Scrapy</div>
          </div>          
        </div>
      </div>
    </div>
    
    <div class="grid grid-cols-1 px-6 lg:grid-cols-2 gap-4">
      <!-- Text area for curl command input -->
      <div class="flex-grow">
        <div class="flex items-center mb-2">
          <!-- SVG Icon -->
          <img src="@/assets/images/number_1_icon.svg" alt="cURL Command Icon" class="h-6 w-6 mr-2">
          <span class="font-bold">cURL Command</span>
        </div>
        <textarea v-model="curlCommand" class="h-96 bg-gray-100 w-full p-4 rounded-lg custom-border-background" placeholder="Enter curl command here..."></textarea>
        <div class="flex justify-between items-center mt-2 width-max-content">
          <span class="text-gray-800 font-medium text-xs sm:text-sm md:text-base">Examples</span>
          <div class="space-x-2">
            <button @click="selectMethod('GET')" :class="{'font-bold': exampleMethod === 'GET'}" class="example-method-btn">GET</button>
            <button @click="selectMethod('POST')" :class="{'font-bold': exampleMethod === 'POST'}" class="example-method-btn">POST</button>
            <button @click="selectMethod('JSON')" :class="{'font-bold': exampleMethod === 'JSON'}" class="example-method-btn">JSON</button>
            <button @click="selectMethod('AUTH')" :class="{'font-bold': exampleMethod === 'AUTH'}" class="example-method-btn">Basic Auth</button>
            <button @click="selectMethod('FILES')" :class="{'font-bold': exampleMethod === 'FILES'}" class="example-method-btn">Files</button>
            <button @click="selectMethod('FORM')" :class="{'font-bold': exampleMethod === 'FORM'}" class="example-method-btn">Form</button>
          </div>
        </div>
      </div>
       <!-- Output container -->
       <div class="flex-grow">
          <div class="flex items-center mb-2">
            <!-- SVG Icon -->
            <img src="@/assets/images/number_2_icon.svg" alt="Code Icon" class="h-6 w-6 mr-2">
            <span class="font-bold">Code</span>
          </div>
          <v-code-block
            class="flex-grow h-96 bg-gray-100 rounded-lg relative overflow-auto"
            :code="code"
            highlightjs
            :lang="getSyntax(language)"
          />
        </div>
    </div>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import * as curlconverter from 'curlconverter';
import { curl2Scrapy } from '@/helpers/curl2ScrapyHelper';

export default {
  props: ['language'],

  methods: {
    toggleSwitch() {
      console.log('toggled');

      this.scrapyOption = this.scrapyOption === 'PythonRequests' ? 'Scrapy' : 'PythonRequests';
    },

    selectMethod(method) {
      this.exampleMethod = method;
      this.curlCommand = this.exampleCommands[method];
    }
  },

  data() {
    return {
      exampleMethod: null,
      isScrapy: false,
      exampleCommands: { // Example curl commands for each method
        GET: 'curl -X GET "http://example.com/api/resource" -H "accept: application/json"',
        POST: 'curl -X POST "http://example.com/api/resource" -H "Content-Type: application/json" -d \'{"key":"value"}\'',
        JSON: 'curl -X GET "http://example.com/api/resource" -H "accept: application/json"',
        AUTH: 'curl -u "user:password" "http://example.com/api/resource"',
        FILES: 'curl -F "file=@/path/to/file" "http://example.com/api/upload"',
        FORM: 'curl -X POST -F "key=value" -F "file=@/path/to/file" "http://example.com/api/form"',
      },
    };
  },

  watch: {
    curlCommand(newCurlCommand) {
      // Convert curl command as necessary
      this.convertCurlCommand(newCurlCommand);

      // Reset exampleMethod to null if curlCommand is empty
      if (!newCurlCommand.trim()) {
        this.exampleMethod = null;
      }
    },
  },


  setup(props) {
    const curlCommand = ref('');
    const code = ref('');
    const scrapyOption = ref('PythonRequests');

    // A method to handle conversion based on the selected language
    const convertCurlCommand = (curlCmd) => {
      if (!curlCmd.trim()) {
        code.value = '';
        return;
      }

      try {
        switch (props.language) {
          case 'python':
            code.value = scrapyOption.value == 'Scrapy' ? curl2Scrapy(curlCmd) : curlconverter.toPython(curlCmd);
            break;
          case 'javascript':
            code.value = curlconverter.toJavaScript(curlCmd);
            break;
          case 'csharp':
            code.value = curlconverter.toCSharp(curlCmd);
            break;
          case 'ruby':
            code.value = curlconverter.toRuby(curlCmd);
            break;
          case 'perl':
            code.value = curlconverter.toPerl(curlCmd);
            break;
          case 'php':
            code.value = curlconverter.toPhp(curlCmd);
            break;
          case 'java':
            code.value = curlconverter.toJava(curlCmd);
            break;
          case 'rust':
            code.value = curlconverter.toRust(curlCmd);
            break;
          case 'r':
            code.value = curlconverter.toR(curlCmd);
            break;
          case 'clojure':
            code.value = curlconverter.toClojure(curlCmd);
            break;
          case 'ocaml':
            code.value = curlconverter.toOCaml(curlCmd);
            break;
          case 'julia':
            code.value = curlconverter.toJulia(curlCmd);
            break;
          case 'dart':
            code.value = curlconverter.toDart(curlCmd);
            break;
          case 'matlab':
            code.value = curlconverter.toMATLAB(curlCmd);
            break;
          case 'lua':
            code.value = curlconverter.toLua(curlCmd);
            break;
          case 'cfml':
            code.value = curlconverter.toCFML(curlCmd);
            break;
          case 'c':
            code.value = curlconverter.toC(curlCmd);
            break;
          case 'elixir':
            code.value = curlconverter.toElixir(curlCmd);
            break;
          case 'kotlin':
            code.value = curlconverter.toKotlin(curlCmd);
            break;
          case 'node':
            code.value = curlconverter.toNodeAxios(curlCmd);
            break;
          case 'powershell':
            code.value = curlconverter.toPowershellWebRequest(curlCmd);
            break;
          case 'swift':
            code.value = curlconverter.toSwift(curlCmd);
            break;
          case 'go':
            code.value = curlconverter.toGo(curlCmd);
            break;
          case 'objectivec':
            code.value = curlconverter.toObjectiveC(curlCmd);
            break;
          case 'json':
            code.value = curlconverter.toJsonString(curlCmd);
            break;
          case 'wget':
            code.value = curlconverter.toWget(curlCmd);
            break;
          case 'http':
            code.value = curlconverter.toHTTP(curlCmd);
            break;
          case 'httpie':
            code.value = curlconverter.toHttpie(curlCmd);
            break;
          case 'har':
            code.value = curlconverter.toHarString(curlCmd);
            break;
          default:
            code.value = 'Unsupported language for conversion.';
        }
      } catch (error) {
        code.value = `Error converting curl command: ${error.message}`;
      }
    };

    const getSyntax = (lang) => {
      if (lang === 'node' || lang === 'json' || lang == 'har') {
        return 'javascript';
      }

      if(lang == 'wget' || lang == 'httpie') {
        return 'bash';
      }

      if(lang == 'cfml') {
        return 'php';
      }
      // Add more conditions as needed
      return lang;
    };

    watch(scrapyOption, () => {
      convertCurlCommand(curlCommand.value);
    });

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return {
      convertCurlCommand,
      curlCommand,
      code,
      scrapyOption,
      getSyntax,
      capitalizeFirstLetter,
    };
  },
};
</script>
