// src/utils/languages.js


export const languages = [
  { name: 'Python', id: "python", logo: require('@/assets/images/svg/python.svg') },
  { name: 'PHP', id: "php", logo: require('@/assets/images/svg/php.svg') },
  { name: 'JavaScript', id: "javascript", logo: require('@/assets/images/svg/javascript.svg')},
  { name: 'NodeJS', id: "node", logo: require('@/assets/images/svg/nodejs.svg')},
  { name: 'Go', id: "go", logo: require('@/assets/images/svg/go.svg')},
  { name: 'Java', id: "java", logo: require('@/assets/images/svg/java.svg')},
  { name: 'Dart', id: "dart", logo: require('@/assets/images/svg/dart.svg')},
  { name: 'Ruby', id: "ruby", logo: require('@/assets/images/svg/ruby.svg')},
  { name: 'R', id: "r", logo: require('@/assets/images/svg/r.svg')},
  { name: 'Rust', id: "rust", logo: require('@/assets/images/svg/rust.svg')},
  { name: 'C', id: "c", logo: require('@/assets/images/svg/c.svg')},
  { name: 'C#', id: "csharp", logo: require('@/assets/images/svg/c_sharp.svg')},
  { name: 'Kotlin', id: "kotlin", logo: require('@/assets/images/svg/kotlin.svg')},
  { name: 'Swift', id: "swift", logo: require('@/assets/images/svg/swift.svg')},
  { name: 'Perl', id: "perl", logo: require('@/assets/images/svg/perl.svg')},
  { name: 'PowerShell', id: "powershell", logo: require('@/assets/images/svg/powershell.svg')},
  { name: 'CFML', id: "cfml", logo: require('@/assets/images/svg/generic_code.svg')},
  { name: 'Clojure', id: "clojure", logo: require('@/assets/images/svg/clojure.svg')},
  { name: 'Elixir', id: "elixir", logo: require('@/assets/images/svg/elixir.svg')},
  { name: 'Julia', id: "julia", logo: require('@/assets/images/svg/julia.svg')},
  { name: 'Lua', id: "lua", logo: require('@/assets/images/svg/lua.svg')},
  { name: 'Matlab', id: "matlab", logo: require('@/assets/images/svg/matlab.svg')},
  { name: 'Objective-C', id: "objectivec", logo: require('@/assets/images/svg/objective_c.svg')},
  { name: 'OCaml', id: "ocaml", logo: require('@/assets/images/svg/ocaml.svg')},
];


export const tools = [
  { name: 'JSON', id: "json", logo: require('@/assets/images/svg/json.svg') },
  { name: 'Wget', id: "wget", logo: require('@/assets/images/svg/generic_code.svg') },
  { name: 'HTTP', id: "http", logo: require('@/assets/images/svg/http.svg')},
  { name: 'HTTPie', id: "httpie", logo: require('@/assets/images/svg/generic_code.svg')},
  { name: 'HAR', id: "har", logo: require('@/assets/images/svg/generic_code.svg')},
];