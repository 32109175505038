<template>
  <div>
    <div class="text-center py-12">
      <h1 class="text-4xl font-bold text-gray-800">Convert curl commands to Python, JS and more</h1>
    </div>

    <div class="pt-5">
      <div class="px-6">
        <h2 class="text-left font-medium leading-7 text-black">Languages</h2>
      </div>
      <div class="main-grid-view">
        <div v-for="language in languages" :key="language.name" @click="navigateToConverter(language)" class="card">
          <a href="#" class="card-block">
            <div class="flex flex-col items-start">
              <img :src="language.logo" :alt="language.name" class="h-8 mr-3">
              <h5 class="lang-nt-heading">{{ language.name }}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <div class="px-6">
        <h2 class="text-left font-medium leading-7 text-black">Tools</h2>
      </div>

      <div class="main-grid-view">
        <div v-for="tool in tools" :key="tool.name" @click="navigateToConverter(tool)" class="card">
          <a href="#" class="card-block">
            <div class="flex flex-col items-start">
              <img :src="tool.logo" :alt="tool.name" class="h-8 mr-3">
              <h5 class="lang-nt-heading">{{ tool.name }}</h5>
            </div>
          </a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { languages, tools } from '@/utils/languages';

export default {
  name: 'HomePage',
  data() {
    return {
    };
  },
  setup() {
    const router = useRouter();

    function navigateToConverter(language) {
      router.push(`/converter/${language.id}`);
    }

    return { languages, tools, navigateToConverter };
  },
};
</script>

<style scoped>

</style>
