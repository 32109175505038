import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './main.css'
import { createVCodeBlock } from '@wdns/vue-code-block';

const VCodeBlock = createVCodeBlock({
  // options
});


createApp(App).use(router).use(VCodeBlock).mount('#app')
